import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { usePostJson } from './http-util';

const getTokenFromLocalStorage = () => localStorage.getItem("auth_token");

const setTokenInLocalStorage = (token) => localStorage.setItem("auth_token", token);

const AuthTokenContext = React.createContext(getTokenFromLocalStorage());

export const useAuthToken = () => {
    const authToken = useContext(AuthTokenContext);
    return authToken;
}

const LoginWrapper = ({ children }) => {
    const [authToken, setAuthToken] = useState(getTokenFromLocalStorage());
    const [error, setError] = useState();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    const postLogin = usePostJson('/login?code=' + code);

    useEffect(() => {
        if (code) {
            postLogin()
                .then(({ token }) => {
                    setTokenInLocalStorage(token)
                    setAuthToken(token);
                    return Promise.resolve(token);
                })
                .catch((error) => {
                    console.log("error", error);
                    setError(error);
                });
        }
    }, []);

    if (!authToken) {
        return <div>
            <p><a href="/login">Login with Google</a></p>
            {error && <p>{error}</p>}
        </div>;
    }

    return (
        <AuthTokenContext.Provider value={authToken}>
            {children()}
        </AuthTokenContext.Provider>
    )
};

LoginWrapper.propTypes = {
    children: PropTypes.func.isRequired
}

export default LoginWrapper;