import PropTypes from 'prop-types';
import React from 'react';
import ToggleButton from '../toggle-button/toggle-button';

const DayToggleButton = ({ date, selected, setSelected }) => {
    const onClick = () => setSelected(!selected);

    const today = new Date();
    const isDateAfterToday = date > today;
    const isToday = date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear()

    return (
        <ToggleButton
            selected={selected}
            disabled={isDateAfterToday}
            highlighted={isToday}
            onClick={onClick}
        >
            {date.getDate()}
        </ToggleButton>
    );
}

DayToggleButton.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    selected: PropTypes.bool.isRequired,
    setSelected: PropTypes.func.isRequired
}

export default DayToggleButton;