import { useAuthToken } from './login-wrapper';

const getJsonOrErrorFromResponse = (response) => {
    if (response.ok) {
        if (response.status === 204) {
            return undefined;
        }
        return response.json();
    }
    else {
        return Promise.reject(`${response.status}: ${response.statusText}`);
    }
};

const getErrorOrGeneric = (error) => {
    return Promise.reject(error || "Unknown error");
}

const getJson = (url, headers) => {
    return fetch(url, {
        headers
    })
        .then(getJsonOrErrorFromResponse, getErrorOrGeneric);
}

const postJson = (url, body, headers) => {
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    })
        .then(getJsonOrErrorFromResponse, getErrorOrGeneric);
}

export const useGetJson = (url) => {
    const authToken = useAuthToken();

    return () => getJson(url, {
        Authorization: authToken
    })
}

export const usePostJson = (url) => {
    const authToken = useAuthToken();

    return (body) => postJson(url, body, {
        Authorization: authToken,
        'Content-Type': 'application/json'
    })
}