import { useState } from "react";

const getMask = date => 1 << date.getDate()

const isBitSet = (value, date) => Boolean(value & getMask(date));

const setBit = (value, date) => value | getMask(date);

const clearBit = (value, date) => value ^ getMask(date);

const useDataManager = (initialData) => {
    const [years, setYears] = useState(initialData);

    const isDaySelected = (date) => {
        const year = years[date.getFullYear()] || [];
        const month = year[date.getMonth()] || 0;
        return isBitSet(month, date);
    }

    const setDaySelected = (date, value) => {
        const y = date.getFullYear();
        const m = date.getMonth();
        const year = years[y] || [];
        const month = year[m] || 0;

        const updatedMonthData = value ? setBit(month, date) : clearBit(month, date);

        const newData = {
            ...years,
            [y]: {
                ...year,
                [m]: updatedMonthData
            }
        };
        setYears(newData);
        return newData;
    }

    return {
        isDaySelected,
        setDaySelected,
        setData: setYears
    }
};

export default useDataManager;