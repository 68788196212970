import React, { useEffect, useState } from 'react';
import YearView from '../year-view/year-view';
import useApiClient from '../../use-api-client';
import useDataManager from '../../use-data-manager';
import LoadingIndicator from './../loading-indicator/loading-indicator';

const YearEditor = () => {
  const { load, save } = useApiClient();
  const { isDaySelected, setDaySelected, setData } = useDataManager({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    load()
      .then(data => {
        setData(data);
        setLoading(false);
      }, setError);
  }, []);

  const updateDaySelected = (date, value) => {
    save(setDaySelected(date, value))
      .catch(setError);
  }

  return (
    <React.Fragment>
      <h1>{year}</h1>

      {error && <div>{error}</div>}
      {!error && <React.Fragment>
        {loading && <LoadingIndicator/>}
        {!loading && <YearView year={year} isDaySelected={isDaySelected} setDaySelected={updateDaySelected} />}
      </React.Fragment>}
    </React.Fragment>
  );
}

export default YearEditor;