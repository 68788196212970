
import { usePostJson, useGetJson } from './http-util';

const convertKeysToInts = (data) => {
  const converted = {};
  Object.keys(data).forEach(year => {
    const months = {};
    converted[parseInt(year)] = months;
    Object.keys(data[year]).forEach(month => months[parseInt(month)] = data[year][month])
  });
  return converted;
};

const useApiClient = () => {
  const postData = usePostJson("/api/data");
  const getData = useGetJson("/api/data");

  const load = () => getData().then(convertKeysToInts);

  const save = (data) => postData(data)

  return { load, save };
}

export default useApiClient;