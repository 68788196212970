import PropTypes from 'prop-types';
import React from 'react';
import DayToggleButton from '../day-toggle-button/day-toggle-button';
import './year-view.scss';

const MONTH_NAMES = [
    'J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D',
];

const getNextDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

const getDatesForYear = (year) => {
    const yearStart = new Date(year, 0, 1);
    const nextYearStart = new Date(year + 1, 0, 1);
    const dates = [];
    for (let d = yearStart; d < nextYearStart; d = getNextDay(d)) {
        dates.push(d);
    }
    return dates;
}

const MonthHeader = ({ name, index }) => {
    const classNames = `yv-month-${index} yv-label`;
    return (
        <div className={classNames}>{name}</div>
    );
};

const DayItem = ({ date, isDaySelected, setDaySelected }) => {
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const classNames = `yv-month-${monthIndex} yv-day-${day}`;

    const onSetSelected = value => setDaySelected(date, value);

    return (
        <div className={classNames}>
            <DayToggleButton
                date={date}
                selected={isDaySelected(date)}
                setSelected={onSetSelected}
            />
        </div>
    );
};

const YearView = ({ year, isDaySelected, setDaySelected }) => {
    const dates = getDatesForYear(year)
    return (
        <div className='yv-container'>
            {MONTH_NAMES.map((name, i) => <MonthHeader key={i} name={name} index={i} />)}
            {dates.map((date, i) => <DayItem 
                key={i} 
                date={date} 
                isDaySelected={isDaySelected} 
                setDaySelected={setDaySelected}
            />)}
        </div>
    );
}

YearView.propTypes = {
    year: PropTypes.number.isRequired,
    isDaySelected: PropTypes.func.isRequired,
    setDaySelected: PropTypes.func.isRequired
}

export default YearView;