import './toggle-button.scss';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleButton = ({ children, disabled, selected, highlighted, onClick }) => {
    const classNames = 'toggle-button'
        + (selected ? ' selected' : '')
        + (highlighted ? ' highlighted' : '');
    return (
        <button
            className={classNames}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

ToggleButton.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    highlighted: PropTypes.bool,
    onClick: PropTypes.func
}

export default ToggleButton;