import React, { useEffect } from 'react';
import '.././scss/App.scss';
import YearEditor from './components/year-editor/year-editor';
import { usePostJson } from './http-util';
import LoginWrapper from './login-wrapper';

const App = () => {
  return (
    <div className="app">
      <LoginWrapper>
        {() => {
          return <YearEditor/>
        }}
      </LoginWrapper>
    </div>
  );
}

export default App;
